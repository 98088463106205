import TagManager from 'react-gtm-module';
import store from '../store/store';

export const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const serverDateFormat = 'yyyy-MM-dd\'T\'HH:mm:ss';
export const serverTimeFormat = 'HH:mm:ss';

interface gtmParams {
  items?: any[]; 
  [key: string]: any;
}

export const gtmEvent = (event: string, params: gtmParams = {}) => {
  const { inApp, customer } = store.getState().session;

  if (params) {
    TagManager.dataLayer({
      dataLayer: {
        event: "reset",
        params: []
      },
    });
  }

  TagManager.dataLayer({
    dataLayer: {
      event,
      channel: inApp ? 'App' : 'Web',
      customerId: customer?.customerSessionToken?.pizzaExpressId,
      params
    }
  });
};

export const throttle = (fn: (...args : any[]) => {}, delay: number) => {
  let timer : NodeJS.Timeout|null;
  return (...args: any[]) => {
    timer && clearTimeout(timer);
    timer = setTimeout(() => fn(...args), delay);
  };
};

export const convertHoursToMinutes = (hhmmss:string) => {
  const strArr = hhmmss.split(':');
  const hours = parseInt(strArr[0]);
  const mins = parseInt(strArr[1]);
  return (hours * 60) + mins;
};

export const addTime = (hhmmss: string, currentTime = new Date()) => {
  const strArr = hhmmss.split(':');
  const hours = parseInt(strArr[0]);
  const mins = parseInt(strArr[1]);
  const newHours = currentTime.getHours() + hours;
  const newMins = currentTime.getMinutes() + mins;
  
  const additionalHours = Math.floor(newMins / 60) + newHours;
  let remainderMins = newMins % 60;
  const remainderStr = (remainderMins < 10) ? '0' + remainderMins : remainderMins;
  const remainingHours = `${additionalHours < 10 ? '0' : ''}${additionalHours}`;
  return `${remainingHours}:${additionalHours > 0 ? remainderStr : newMins}`;
};

export const convertDeliveryTimeToDisplayTime = (time: string) => {
  if (time[0] === '0' && time[1] === '0') return time.split(':')[1];
  return time[1] + ':' + time.split(':')[1];
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (!parts) return undefined;
  if (parts.length === 2) return parts.pop()?.split(';').shift();
};

export const freshRelevance = (eventName: string, eventData: any | null, options: any) => {
  if (window.$TB) {
    window.$TB.hooks.fireEvent(eventName, eventData, options);
  }
};

export const freshTrackClick = (meta: any, url: string, productId: string) => {
  if(window.$TB?.trackClick) {
    window.$TB.trackClick(meta, url, productId);
  }
};

export const getDistanceText = (distance: number, brand: string) => {
  if (!distance || !+distance) return '';

  if (brand == 'MI') {
    return (~~distance / 1000).toFixed(2) + ' km';
  }
  return (~~distance / 1609.34).toFixed(2) + ' mi';
};