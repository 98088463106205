import { useState, useEffect, useRef, useMemo } from 'react';
import minus from '../img/icons/basket-card-minus.svg';
import plus from '../img/icons/basket-card-plus.svg';
import ExpandButton from './ExpandButton';
import { useDispatch } from 'react-redux';
import {
  getCals,
  getExtras,
  getCardButtons,
  setDefaultSubProducts,
  dereffItems,
  useQuickAddProperties
} from '../helpers/basketHelpers';
import DropDown from './DropDown';
import DropDownField from './DropDownField';
import chevronDownRed from '../img/icons/chevronDownRed.svg';
import DietaryIcons from './DietaryIcons';
import { setBaseSelections } from '../store/menuSlice';
import { freshRelevance, gtmEvent } from '../helpers/commonHelpers';
import { useConfig } from '../helpers/useConfig';
import { ChoiceModalWrapper } from '../helpers/modalHelpers';
import { useAppSelector } from '../store/storeTypes';
import { Tags } from './Tags';
import store from '../store/store';

const getBundleOptions = (bundle) => {
  const optionsArr = bundle.sections.reduce((acc, cur) => {
    const products = cur.products.map(p => ({
      name: p.product.subProducts?.[0]?.subProducts?.[0].name ? `${p.product.name} ${p.product.subProducts?.[0]?.subProducts?.[0].name}` : p.product.name,
      cost: p.product.upliftPrice ? p.product.upliftPrice : 0,
      quantity: p.product.quantity
    }));
    return acc.concat(products);
  }, []);
  return optionsArr;
};

const MenuItem = props => {
  const isTabletScreen = useAppSelector(state => state.session.isTabletScreen);
  const restaurant = useAppSelector(state => state.session.restaurant);
  const baseSelections = useAppSelector(state => state.menu.baseSelections);
  const showCalories = useAppSelector(state => state.menu.showCalories);
  const currentTier = useAppSelector(state => state.session?.customer?.loyalty?.customer?.currentTier?.name);
  const loyaltySchemeVersion = useAppSelector(state => state.session?.customer?.loyalty?.customer?.loyaltySchemeVersion);
  const duoRomanaIds = useAppSelector(state => state.session.frontEndConfig?.duoRomanaIds);
  const { id, name, cost, calculatedCost, imageUrl, description, quantity, subProducts, customID, basePrice, dietPreferences, outOfStock } = props.item;
  const { columnWidth, menuItem, bundleItem, numberInBasket, addItem, removeItem, navigateTo, isBundle, dietaryOptions, idx } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const textRef = useRef(null);
  const [showButton, setShowButton] = useState(false);
  const [extras, setExtras] = useState();
  const [selectedBase, setSelectedBase] = useState();
  const [selectedBaseCost, setSelectedBaseCost] = useState(cost || basePrice);
  const dispatch = useDispatch();
  const config = useConfig();
  const brand = config?.BRAND;
  const [quickAddModalState, setQuickAddModalState] = useState(false);
  const {isCustomisable, isAddable, isPizza, popupRequired} = useQuickAddProperties(props.item);
  const [baseError, setBaseError] = useState('');

  let displayCost = calculatedCost || selectedBaseCost;
  if (!displayCost) displayCost = 0;

  useEffect(() => {
    if (subProducts && subProducts[0].subProducts.length > 1) {
      const matchingBaseSelection = baseSelections.find(b => b.product === id);
      if (matchingBaseSelection) {
        setSelectedBase(matchingBaseSelection.base);
        setSelectedBaseCost(subProducts[0].subProducts.find(s => s.id === matchingBaseSelection.base).cost);
      } else {
        const hasClassic = props.item.subProducts?.[0]?.subProducts?.find(sp => sp.id === '26');
        if(hasClassic) {
          setSelectedBaseCost(hasClassic.cost);
        } else {
          const defaultSelection = subProducts[0].defaultSubProduct;
          const lastSelection = subProducts[0].subProducts[subProducts[0].subProducts.length - 1].id;
          const selection = subProducts[0].subProducts.find(sp => sp.id === (defaultSelection ?? lastSelection));
          if (selection) {
            setSelectedBaseCost(selection.cost);
          }
        }
      }
    }
  }, [subProducts, baseSelections]);

  const expand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (customID && !isBundle) {
      const newExtras = getExtras(props.item);
      setExtras({ base: newExtras[0]?.name?.split('-')[0], toppings: newExtras.slice(1) });
    } else if (isBundle) {
      const newExtras = getBundleOptions(props.item);
      setExtras({ items: newExtras });
    } else {
      setExtras({ items: [] });
    }
  }, [props.item]);

  const updateBaseSelection = (v, e) => {
    setBaseError('');
    setSelectedBase(e);
    setSelectedBaseCost(subProducts[0].subProducts.find(base => base.id === e).cost);

    if (!baseSelections.some(b => b.product === id)) {
      dispatch(setBaseSelections([...baseSelections, { product: id, base: e }]));
    } else {
      dispatch(setBaseSelections(baseSelections.map(base => base.product === id ? { product: id, base: e } : base)));
    }
  };
  
  const GetItemChoice = async (choices, question) => {
    try {
      return await new Promise((resolve, reject) => {
        setQuickAddModalState({
          resolve,
          reject,
          choices,
          question
        });
      });
    } catch {
      return false;
    }
  }

  const isOverflown = (ref) => {
    if (ref.current !== null) {
      return setShowButton(ref.current?.offsetHeight < ref.current?.scrollHeight || ref.current?.offsetWidth < ref.current?.scrollWidth);
    }
  };

  const handleCustomiseClick = () => {
    const { customer, basket, menu: { activeCategoryName }, session: { inApp } } = store.getState();
    const event = {
      'currency': brand === 'PE' ? 'GBP' : 'EUR',
      clickAndCollectAction: basket.isDelivery ? 'Delivery_Product_Details_Clicked' : 'Collection_Product_Details_Clicked',
      channel: inApp ? 'App' : 'Web',
      value: basket.total,
    };
    if (customer?.customerSessionToken?.pizzaExpressId) {
      event.customerId = customer.customerSessionToken.pizzaExpressId;
    }

    const item = {
      item_name: props.item.name,
      price: props.item.cost ? props.item.cost : props.item.basePrice, // Bundle obj uses basePrice
      quantity: 1,
      item_brand: brand,
      item_category: activeCategoryName
    };
    if (props.item.id) {  // Bundle obj doesn't have id
      item['item_id'] = props.item.id;
    }
    else if(props.item.eposDiscountId) {
      item['item_id'] = props.item.eposDiscountId;
    }
    event['items'] = [item];
    gtmEvent('product_details_event', event);
  };

  useEffect(() => {
    isOverflown(textRef);
  }, [textRef]);

  const quickAdd = async () => {
    if (isCustomisable) {
      const choices = [];
      let quickAddItem = dereffItems(props.item);

      if (quickAddItem.subProducts[0].defaultSubProduct && selectedBase) {
        const selectedDefault = quickAddItem.subProducts[0].subProducts.find(base => base.id === selectedBase);
        quickAddItem.displayPrice = selectedDefault.cost;
        choices.push({ id: quickAddItem.subProducts[0].id, defaultId: selectedBase });
      }

      if (!selectedBase && isPizza) {
        setBaseError('Please make a selection to proceed to basket.');
        return;
      }

      if (popupRequired) {
        const choiceId = await GetItemChoice(popupRequired.subProducts, popupRequired);
        if (!choiceId) return;
        choices.push({ id: popupRequired.id, defaultId: choiceId });
      }

      setDefaultSubProducts(quickAddItem, choices);
      freshRelevance('pageChange', null, { 'sendBeacon': true });
      addItem(quickAddItem, true);
    } else {
      addItem(props.item);
    }
  };

  const cardButtons = getCardButtons(props, quickAdd, navigateTo, restaurant?.id, id, handleCustomiseClick, isCustomisable, isAddable);

  const getCalories = () => {
    if (!showCalories) return null;
    const cals = getCals(props.item, selectedBase, true);
    if (!cals?.length) return null;
    return <span className="calories">{cals}</span>;
  };
  
  let discountTotal = 0;
  const discounts = props?.discounts?.filter(d => d.id === id || d.id === customID) || [];
  discounts.forEach(discount => discountTotal += discount.amount);
  const displayCostAfterDiscount = displayCost - discountTotal;

  const commonTagProps = {
    item: props.item,
    currentTier: currentTier,
    loyaltySchemeVersion: loyaltySchemeVersion,
    showLocked: true,
    excludeChildTags: false
  }

  const displayCalories = getCalories();

  return (
    <div
      className={`basket-card-wrapper column is-${columnWidth} is-flex-direction-column is-align-items-stretch is-align-content-stretch ${outOfStock ? 'outofstock' : ''}`}
      data-testid={bundleItem ? props.item.name : id}
      onClick={() => {
        if (!isTabletScreen) return;
        handleCustomiseClick();

        if (bundleItem) {
          navigateTo(`/bundle/${name}`);
        } else {
          navigateTo(`/menu/${restaurant.id}/customise/${id}`, { menuItem: props.item })
        }
      }}
    >
      <div className={`basket-card is-radius-very-large ${menuItem && !isTabletScreen ? '' : 'remove-border'} ${numberInBasket > 0 ? 'has-banner' : ''}`}>
        <div className='mobile-item-banner'></div>
        {numberInBasket > 0
          ? (
            <>
              <div className={'number-in-basket'}>
                {numberInBasket} added to basket
              </div>
              <div className='mobile-item-count'>
                <p>{numberInBasket}</p>
              </div>
            </>
          )
          : null
        }
        <div className={`basket-card-image-wrapper`}>
          <figure style={{ maxWidth: '100%' }}>
            <img className="image" src={imageUrl} alt={name} loading={props.catIndex > 0 ? 'lazy' : 'eager'} />
          </figure>
        </div>
        <div className='basket-card-content-layout'>
          <div className={'basket-card-content-wrapper'}>
            <Tags {...commonTagProps} wrapperClass={'all-tags-wrapper desktop'}/>
            <div className="card-title-diet-wrapper">
              <p className="card-title is-text-weight-semibold">
                {name}
              </p>
              {
                !duoRomanaIds?.includes(id) &&
                <>
                  {menuItem ? dietaryOptions && <DietaryIcons icons={dietaryOptions} style={{ alignItems: 'flex-start', marginTop: '0px', minWidth: `${(dietaryOptions?.length * 32)}px` }} /> : dietPreferences && <DietaryIcons style={{ alignItems: 'flex-start', minWidth: `${(dietPreferences?.length * 32)}px` }} icons={dietPreferences} />} {/*todo , marginTop: '18px'*/}
                </>
              }
            </div>
            {/* Title for Mobile - has to be here */}
            <div className="card-title-wrapper-mobile">
              <p className="card-title is-text-weight-semibold">
                {name}
              </p>
            </div>
            {/* Price and Calories for Desktop */}
            {(displayCost || displayCalories !== '') && <div className="card-price-calories-wrapper">
              {discounts?.length > 0 ? (
                <div className="price">
                  <small>
                    <strike>{displayCost?.toFixed(2)}</strike>
                  </small>
                  &nbsp;
                  <span className="is-text-weight-semibold">
                    {displayCostAfterDiscount !== 0 ? <>{(displayCostAfterDiscount).toFixed(2)}</> : <>Free</>}
                  </span>
                </div>) : (
                <span className="price is-text-weight-semibold">
                  {displayCost?.toFixed(2)}
                </span>
              )}
              {displayCalories}
            </div>}
            {/* Calories and Dietary Icons for Mobile */}
            {!bundleItem && (displayCalories || (dietaryOptions && dietaryOptions.length > 0)) && !duoRomanaIds?.includes(id) &&
              <div className="card-calories-diet-wrapper-mobile">
                {displayCalories}
                <DietaryIcons icons={dietaryOptions} style={{ alignItems: 'center', marginTop: '0px', minWidth: `${(dietaryOptions?.length * 16)}px` }} iconSize={16} />
              </div>
            }

            {extras?.base ? <span>{extras.base}</span> : null}
            {/* Description */}
            {menuItem && description &&
              <div className='description-wrapper'>
                <p
                  ref={textRef}
                  className={`description ${isExpanded ? 'expanded' : ''}`}
                >
                  {description}
                </p>
                {
                  (showButton) &&
                  <div className="expand-btn-wrapper level-item">
                    <ExpandButton invert={!isExpanded} callback={expand} size="medium" />
                  </div>
                }
              </div>
            }
            <div className="card-price-wrapper-mobile">
              {discounts?.length > 0 ? (
                <div className="price">
                  <small>
                    <strike>{displayCost.toFixed(2)}</strike>
                  </small>
                  &nbsp;
                  <span className="is-text-weight-semibold">
                    {displayCostAfterDiscount !== 0 ? <>{(displayCostAfterDiscount).toFixed(2)}</> : <>Free</>}
                  </span>
                </div>) : (
                <span
                  className="price is-text-weight-semibold"
                >
                  {displayCost.toFixed(2)}
                </span>
              )}
            </div>
          </div>

          <div className={'button-container'}>
            {menuItem ?
              bundleItem ? (
                <>
                  <div className="button-wrapper block is-flex">
                    <div className="level is-align-items-stretch-tablet is-flex-grow-1 wrap-desktop-only is-stack-desktop">
                      <div className="level-item">
                        <button
                          className='btn btn-primary is-flex-grow-1-mobile is-fullwidth'
                          disabled={outOfStock} data-testid='navigate-bundle'
                          onClick={() => {
                            handleCustomiseClick();
                            navigateTo(`/menu/${restaurant?.id}/bundle/${name}`);
                          }}
                        >
                          {outOfStock ? 'Out of stock' : 'Customise'}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {
                    isPizza ?
                      <div className='is-flex-grow-1-mobile is-fullwidth'>
                        <DropDown>
                          <DropDownField
                            name='base'
                            valueCallback={updateBaseSelection}
                            value={selectedBase}
                            options={subProducts[0].subProducts.map(option => ({
                              label: option.name,
                              value: option.id,
                              price: option.cost.toFixed(2),
                              option: option,
                              dietPreferences: option.dietPreferences.filter(d => d === 'Gluten-Free'), cost: option.cost.toFixed(2),
                              isOutOfStock: option.outOfStock
                            }))}
                            currentTier={currentTier}
                            loyaltySchemeVersion={loyaltySchemeVersion}
                            style={{}}
                            greyBg
                            titleExtraIdentifier={`${name} choose your base`}
                            idx={idx}
                            hasGap
                            disabled={outOfStock}
                            required={true}
                            placeHolder='Choose your base*'
                            error={baseError}
                            dropdownErrorIcon={chevronDownRed}
                            flexibleWidth
                          />
                        </DropDown>
                      </div>
                      :
                      null
                  }
                  <div className={`button-wrapper block level is-align-items-stretch-tablet is-stack-desktop is-flex-shrink-1 ${cardButtons.length === 1 ? 'single-button' : ''}`}>
                    {cardButtons.map((button, i) => (
                      <button key={`button-${i}`}
                        onClick={() => {
                          if (button.onClick) {
                            button.onClick();
                          }
                        }}
                        disabled={button.disabled}
                        className={`btn btn-${cardButtons.length - 1 == i ? 'primary' : 'secondary'} is-flex-grow-1-mobile is-fullwidth`}
                        style={{ paddingRight: 4, paddingLeft: 4 }} data-testid={button.testId}>
                        {button.text}
                      </button>
                    ))}
                  </div>
                </>
              ) : (
                !outOfStock && (
                  <div className="block is-flex is-justify-content-flex-end mb-0 mt-3-mobile">
                    <div className="level is-mobile">
                      {
                        customID &&
                        <div className="level-item more-space is-narrow">
                          <a className="link dm-sans" style={{ fontSize: 16, fontWeight: 400 }} onClick={() => navigateTo(`/menu/${restaurant.id}/${isBundle ? 'bundle' : 'customise'}/${isBundle ? name : id}`, { customID, quantity })}>Edit</a>
                        </div>
                      }
                      <div data-testid='decrease' className="level-item more-space is-narrow" onClick={() => removeItem(props.item)}>
                        <img src={minus} className="icon" />
                      </div>
                      <div className="level-item more-space is-narrow">
                        <div className="icon">
                          <p className='basket-card-quantity' data-testid='quantity'>{quantity}</p>
                        </div>
                      </div>
                      <div data-testid='increase' className="level-item is-narrow" onClick={() => addItem(props.item)}>
                        <img src={plus} className="icon" />
                      </div>
                    </div>
                  </div>)
              )}
          </div>
        </div>
        <Tags
          {...commonTagProps}
          wrapperClass={'all-tags-wrapper mobile'}
        />
      </div>
      {
        !!quickAddModalState &&
          <ChoiceModalWrapper isOpen={!!quickAddModalState} state={quickAddModalState} onClose={() => setQuickAddModalState(false)} />
      }
    </div >
  );
};

export default MenuItem;
