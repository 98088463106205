import { findAllCustomOptions } from "./basketHelpers";

export const customItemOptionsToGtmParams = (customItem, params) => {
    const options = findAllCustomOptions(customItem);
    let count = 1;
    options.forEach((option) => {
        if (option.quantity) {
            params[`item_category${count}`] = `${option.name} x ${option.quantity}`;
            count++;
        } else {
            params[`item_category${count}`] = `${option.name} x 1`;
            count++;
        }
    });
};

export const generateGtmEvent = (action, basket, inApp, brand, customer) => {
    const event = {
        channel: inApp ? 'App' : 'Web',
        currency: brand === 'PE' ? 'GBP' : 'EUR',
        clickAndCollectAction: basket.isDelivery ? `Delivery_${action}` : `Collection_${action}`,
        value: basket.total,
    };
    if (customer?.customerSessionToken?.pizzaExpressId) {
        event.customerId = customer.customerSessionToken.pizzaExpressId;
    }
    return { ...event };
};

export const generateGtmItemParams = (body, brand, isBundle) => {
    const params = {
        item_id: body.id ? body.id : body.customID,
        item_name: body.name,
        item_brand: brand,
        quantity: body.quantity
    };

    if (isBundle) {
        params.item_category = 'Bundles';
        body.sections.flatMap(el => el.products).forEach((pr, index) => {
            params[`item_category${index + 1}`] = `${pr.product.name} x ${pr.product.quantity || 1}`;
        });
    } else {
        params.item_category = body.categoryName;
        if (body.customID) customItemOptionsToGtmParams(body, params);
    }

    return params;
};