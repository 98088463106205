import { Fragment } from 'react';
import { useAppSelector } from '../store/storeTypes';
import stars332 from '../assets/images/stars-3-32.svg';
import starsSmall32 from '../assets/images/stars-small32.svg';
import '../styles/KidsSetPrice.sass';
import { Tags } from './Tags';
import { MenuProduct } from 'src/types/MenuTypes';

interface KidsSetPriceProps {
  item: MenuProduct,
  price: number;
  piccoloId: string;
  navigateTo: (path: string, state?: any) => void;
}

const title = ['Pizza', 'Dough Balls', 'Drink'];

const KidsSetPrice = ({ item, navigateTo }: KidsSetPriceProps) => {
  const restaurant = useAppSelector(state => state.session.restaurant);
  const currentTier = useAppSelector(state => state.session?.customer?.loyalty?.customer?.currentTier?.name);
  const loyaltySchemeVersion = useAppSelector(state => state.session?.customer?.loyalty?.customer?.loyaltySchemeVersion);

  return (
    <section className="kids-set-price" >
      <div
        className='kids-set-price-wrapper'
        onClick={() => {
          navigateTo(`/menu/${restaurant?.id}/customise/${item.id}`, { menuItem: item })
        }}
      >
        <header className="kids-set-price__header">
          {title && title?.length > 0 ?
            <span className="kids-set-price__title">
              {title?.map((t, index) => {
                return (
                  <Fragment key={t}>
                    <span>{t}</span>
                    {index !== title?.length - 1 ? index % 2 === 0 ?
                      <span className="kids-set-price__title--image-wrapper"><img src={stars332} alt="" /></span>
                      : <span><img src={starsSmall32} alt="" /></span>
                      : null}
                  </Fragment>
                )
              })}
            </span>
            : null}
        </header>
        <article className="set-price-display">
          <div className="set-price-display__price-wrapper">
            <p className="set-price-display__price">{item?.displayPrice?.toFixed(2)}</p>
          </div>
        </article>
        <Tags
          item={item}
          currentTier={currentTier}
          loyaltySchemeVersion={loyaltySchemeVersion}
          showLocked
          excludeChildTags
          outOfStockDisabled
          wrapperClass={'all-tags-wrapper centered'}
        />
      </div>
    </section>

  );
}

export default KidsSetPrice;