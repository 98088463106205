import { useState, useEffect, lazy, Suspense, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from './store/storeTypes';
import { Navigate, Route, useNavigate, useLocation, Routes, useMatch } from 'react-router-dom';
import { hasCustomerDetails, existsButNeedsMobileNumber } from './AppController';
import BasketController from './features/basket/BasketController';
import MenuController from './features/menu/MenuController';
import {
  useAddBundleToBasketMutation,
  useAddToBasketMutation,
  useCheckOutOfStockMutation,
  useQuickAddItemAndRewardMutation,
  useQuickAddItemMutation,
  useQuickRemoveItemMutation,
  useQuickSelectItemMutation,
  useRemoveBundleFromBasketMutation,
  useRemoveFromBasketMutation
} from './services/basket.api';
import { applyDefaultSubProducts } from './helpers/basketHelpers';
import { generateGtmEvent, generateGtmItemParams } from './helpers/gtmEventHelpers';
import { historyMW } from './helpers/routingHelpers';
import { DeliveryTimeUpdateModalWrapper, TemporarilyClosedModalWrapper } from './helpers/modalHelpers';
import { setUpdatedDeliveryTime } from './store/sessionSlice';
import hash from 'object-hash';
import { useLazyGetOrderForTodayQuery } from './services/order.api';
import LoaderBlack from '../src/img/common/Loader_360x360.gif';
import { gtmEvent } from './helpers/commonHelpers';
import { useConfig } from './helpers/useConfig';
import Loading from './components/Loading';
import Bundle from './features/menu/pages/Bundle';
import Customise from './features/menu/pages/Customise';
// import OrderSummary from './features/payment/pages/OrderSummary';
const CheckoutController = lazy(() => import('./features/checkout/CheckoutController'));
const PasswordResetConfirmation = lazy(() => import('./features/checkout/pages/PasswordResetConfirmation'));
const ForgottenPassword = lazy(() => import('./features/checkout/pages/ForgottenPassword'));
const OrderSummary = lazy(() => import('./features/payment/pages/OrderSummary'));
const PaymentController = lazy(() => import('./features/payment/PaymentController'));

const OrderJourneyController = ({ routeRestaurantId, refs, setShowChangeRestaurantModal, refreshBasket, setDisablePaymentBackButton, disablePaymentBackButton, isAppStateFetching }) => {
  const { customer, restaurant, inApp, collectionTime, deliveryTimeUpdated } = useAppSelector(state => state.session);
  const menuRestaurantId = useAppSelector(state => state.menu.restaurantId);
  const categories = useAppSelector(state => state.menu.categories);
  const basket = useAppSelector(state => state.basket);
  const appApproved = useAppSelector(state => state.session?.customer?.appApproved);
  const loyalty = useAppSelector(state => state.session?.features.loyalty) && appApproved;
  const config = useConfig();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [removeFromBasket, { isLoading: isRemovingFromBasket }] = useRemoveFromBasketMutation();
  const [addToBasket, { isLoading: isAddingToBasket }] = useAddToBasketMutation();
  const [quickAddItemAndReward, { isLoading: isQuickAddingToBasket }] = useQuickAddItemAndRewardMutation();
  const [quickAddItem] = useQuickAddItemMutation();
  const [quickRemoveItem] = useQuickRemoveItemMutation();
  const [checkOutOfStock] = useCheckOutOfStockMutation();
  const [addBundleToBasket, { isLoading: isAddingBundleToBasket }] = useAddBundleToBasketMutation();
  const [removeBundleFromBasket, { isLoading: isRemovingBundleFromBasket }] = useRemoveBundleFromBasketMutation();
  const [quickSelectItemMutation, { isLoading: isQuickSelectingItem }] = useQuickSelectItemMutation();
  const [getOrder, { data, isLoading: isOrderLoading, isFetching, isUninitialized }] = useLazyGetOrderForTodayQuery();
  const filters = useAppSelector(state => state?.menu?.filters)
  const isUpdatingBasket =
    isRemovingFromBasket ||
    isAddingToBasket ||
    isAddingBundleToBasket ||
    isRemovingBundleFromBasket ||
    isQuickAddingToBasket ||
    isQuickSelectingItem;
  const { pathname, search } = useLocation();
  const query = new URLSearchParams(search);
  const [notification, setNotification] = useState(false);
  const isDelivery = useMatch('/delivery/*');
  const brand = config?.BRAND;

  const handleRemoveItem = async (item) => {
    if (isUpdatingBasket) return;

    let response;
    const isBundle = !!item.eposDiscountId

    const body = { ...item, quantity: 1, restaurantId: restaurant?.id };
    let event = generateGtmEvent('Removed_Items', basket, inApp, brand, customer);
    let params = generateGtmItemParams(body, brand, isBundle);
    event['items'] = [params];

    response = await handleRemoval(body, params, isBundle);

    if (response?.data?.status === 'OK') {
      refreshBasket();
      gtmEvent('remove_from_cart', event);
    }
  };

  const handleRemoval = async (body, params, isBundle) => {
    const response = isBundle
      ? await removeBundleFromBasket(body)
      : await removeFromBasket([body]);

    if (response?.data?.status === 'OK') {
      const price = calculatePriceChange(body, response, false, isBundle);
      params['price'] = price;
    }

    return response;
  };

  const handleOutOfStockRemovedItems = async () => {
    gtmEvent(basket.isDelivery ? 'Delivery_Remove_Items' : 'Collection_Remove_Items');
    historyMW.replace('/basket', basket.isDelivery, navigate);
    refreshBasket();
  };

  const calculatePriceChange = useCallback((prevItem, response, isAddition, isBundle) => {
    const items = response.data[isBundle ? 'bundles' : 'items'];
    const newItem = items.find(i => (i.customID && i.customID === prevItem.customID) || (i.id && i.id === prevItem.id));
    const newItemCost = newItem?.calculatedCost ?? 0;

    return isAddition
      ? +(newItemCost - prevItem.calculatedCost).toFixed(2)
      : +(prevItem.calculatedCost - newItemCost).toFixed(2);
  }, []);

  const handleAddition = useCallback(async (body, params, isBundle) => {
    const response = isBundle
      ? await addBundleToBasket(body)
      : await addToBasket([body]);

    if (response?.data?.status === 'OK') {
      const price = calculatePriceChange(body, response, true, isBundle);
      params['price'] = price;
    }

    return response;
  }, [calculatePriceChange, addBundleToBasket, addToBasket]);

  const handleAddItem = useCallback(async (item, custom) => {
    if (isUpdatingBasket) return;

    let response;
    const isBundle = !!item.eposDiscountId;

    if (!item.customID) {
    item = applyDefaultSubProducts(item);
    }

    if (custom) {
      item = { ...item, customID: hash(item) };
    }
    const body = { ...item, quantity: 1, restaurantId: restaurant?.id };
    let event = generateGtmEvent('Add_Items', basket, inApp, brand, customer);
    let params = generateGtmItemParams(body, brand, isBundle);
    event['items'] = [params];

    response = await handleAddition(body, params, isBundle);

    if (response?.data?.status === 'OK') {
      setNotification({ visible: true, item });
      refreshBasket();
      gtmEvent('add_to_cart', event);
    }
  }, [refreshBasket, setNotification, handleAddition]);

  const handleQuickAddItemAndReward = async (productId, portionTypeId, thirdPartyRewardTypeId) => {
    let response = await quickAddItemAndReward({
      productId,
      portionTypeId,
      thirdPartyRewardTypeId
    });

    if (response.data) {
      await handleAddItem(response.data);
      await quickSelectItemMutation({
        productId,
        portionTypeId,
        thirdPartyRewardTypeId
      });
    }
  };

  window.quickAdd = (productId, portionId) => quickAddItem({ productId, portionId });
  window.quickRemove = (productId, portionId) => quickRemoveItem({ productId, portionId });
  window.quickRoute = (path, isReplace) => isReplace ? historyMW.replace(path, basket.isDelivery, navigate) : historyMW.push(path, basket.isDelivery, navigate);
  window.getBasket = () => ({ items: basket.items, bundles: basket.bundles });
  window.checkOutOfStock = async (productIds) => await checkOutOfStock(productIds);

  const handleUpdatedDeliveryModalClose = () => dispatch(setUpdatedDeliveryTime(false));

  const getOrderConfirmation = async () => {
    try {

      if (!basket.order && inApp && search) {
        const orderId = query.get('ord');
        const restaurantId = query.get('rid');
        if (!orderId) return;
        await getOrder({ id: orderId, restaurantId: restaurantId });
      }
    } catch (err) {
      console.log(err)
    }
  };

  useEffect(() => {
    getOrderConfirmation();
  }, []);

  const homeRoute = isDelivery ? '/delivery' : '/collection'

  const loading = () => <Loading show inApp={inApp} />;
  return (
    <>
      <Routes>
        <Route path={'menu/:restaurantId?/*'} element={
          (menuRestaurantId.toString() == routeRestaurantId)
            ? <MenuController
              key={menuRestaurantId}
              refs={refs}
              onAddItem={handleAddItem}
              onRemoveItem={handleRemoveItem}
              notification={notification}
              setNotification={setNotification}
              refreshBasket={refreshBasket}
            />
            : loading()
        } />
        <Route path={'checkout/*'}>
          <Route path={'forgottenPassword'} element={
            <Suspense fallback={loading()}>
              <ForgottenPassword />
            </Suspense>
          } />
          <Route path={'details'} element={!hasCustomerDetails(basket)
            ? <Navigate to={'login'} replace />
            : !basket?.items?.length && !basket?.bundles?.length
              ? <Navigate to={`../menu/${restaurant?.id}`} replace />
              :
              <Suspense fallback={loading()}>
                <CheckoutController onRemoveItem={handleOutOfStockRemovedItems} />
              </Suspense>
          } />
          <Route path={'*'} element={
            <Suspense fallback={loading()}>
              <CheckoutController onAddItem={handleQuickAddItemAndReward} onRemoveItem={handleOutOfStockRemovedItems} />
            </Suspense>
          } />
        </Route>
        <Route path={'payment/*'} element={!basket?.items?.length && !basket?.bundles?.length
          ? !restaurant?.id
            ? <Navigate to={homeRoute} replace />
            : <Navigate to={`../menu/${restaurant?.id}`} replace />
          : !hasCustomerDetails(basket, customer)
            ? existsButNeedsMobileNumber(customer)
              ? <Navigate to={'../checkout/verifyMobile'} replace />
              : <Navigate to={'../checkout/login'} replace />
            : !collectionTime && !basket.delivery ? <Navigate to={'../checkout/details'} replace />
              : basket.delivery && !basket.delivery.deliveryAddress.addressLine1 ? <Navigate to={'../checkout/details'} replace />
                : <Suspense fallback={loading()}>
                  <PaymentController setDisablePaymentBackButton={setDisablePaymentBackButton} disablePaymentBackButton={disablePaymentBackButton} onRemoveItem={handleOutOfStockRemovedItems} />
                </Suspense>
        }
        />
        <Route path={'basket'} element={!restaurant?.id
          ? <Navigate to={homeRoute} replace />
          : <BasketController
            refs={refs}
            onAddItem={handleAddItem}
            onRemoveItem={handleRemoveItem}
            selectingRewards={false}
          />}
        />
        <Route path={'loyalty'} element={!restaurant?.id
          ? <Navigate to={homeRoute} replace />
          : <BasketController
            refs={refs}
            onAddItem={handleAddItem}
            onRemoveItem={handleRemoveItem}
            selectingRewards={true}
          />}
        />
        <Route exact path={'passwordResetConfirmation'} element={
          <Suspense fallback={loading()}>
            <PasswordResetConfirmation />
          </Suspense>
        } />
        <Route exact path={'order/confirmation'} element={
          basket.order || data
            ? <Suspense fallback={loading()}>
              <OrderSummary
                isRouted={!!query.get('ord')}
                loyalty={loyalty}
                onChangeRestaurant={() => setShowChangeRestaurantModal(true)}
                order={basket.order || data}
                getOrder={getOrder}
              />
            </Suspense>
            : isOrderLoading || isFetching || (isUninitialized && search)
              ? <div style={{ alignContent: 'center', flexDirection: 'column', display: 'flex', alignItems: 'center', paddingTop: '128px', paddingBottom: '128px' }}>
                <img src={LoaderBlack} style={{ width: 64, height: 64 }} alt="logo" />
              </div>
              : <Navigate to={homeRoute} replace />
        }
        />
      </Routes>
      <Routes>
        <Route path="/bundle/:itemId" element={
          <Bundle refs={refs} restaurantId={menuRestaurantId} filters={filters} refreshBasket={refreshBasket} />
        } />
        <Route path="/customise/:itemId" element={
          <Customise refs={refs} restaurantId={menuRestaurantId} refreshBasket={refreshBasket} setNotification={setNotification} />
        } />
      </Routes >
      <DeliveryTimeUpdateModalWrapper
        onClose={handleUpdatedDeliveryModalClose}
        show={pathname !== '/collection' && pathname !== '/delivery' && deliveryTimeUpdated}
        restaurantId={restaurant?.id}
        deliveryEstimate={basket?.delivery?.deliveryQuote?.duration}
        inApp={inApp}
      />
      <TemporarilyClosedModalWrapper
        restaurant={restaurant}
        isDelivery={basket.isDelivery}
        inApp={inApp}
        show={(!isAppStateFetching && categories?.length === 0) || (restaurant?.isCollectionDisabled && !basket.isDelivery) || (restaurant?.isDeliveryDisabled && basket.isDelivery)}
      />
    </>
  );
};

export default OrderJourneyController;